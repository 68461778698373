import React, { useState } from 'react';
import './styles.css';
import moment from 'moment';
import CustomerSelect from '../../../containers/CustomerSelect';
import SearchButton from '../../common/SearchButton';
import FilterField from '../../common/FilterField';
import { COLUMN_TYPE_DATE } from '../../../utils/constants';
import { sanitizeMomentValue } from '../../../utils/validations';
import AppDateTimePicker from '../../common/AppDateTimePicker';

const Filters = ({ isSearching, onUpdate, data }) => {
  const initialState = {
    startDate: moment(),
    endDate: moment(),
  };

  const [state, setState] = useState(initialState);

  const onSearch = () => {
    onUpdate(state);
  };

  const onChangeValue = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };

  const onChange = (key, value, type) => {
    let newValue;
    switch (type) {
      case COLUMN_TYPE_DATE: {
        newValue = sanitizeMomentValue(value);
        break;
      }
      default: {
        newValue = value;
      }
    }
    setState({ ...state, [key]: newValue });
  };

  const onChangeDateTime = (key, type) => (dateTime) => onChange(key, dateTime, type);

  const onChangeCustomerGroup = (value) => {
    if (value) {
      const customerGroupIds = value.map((obj) => obj.value).join(',');
      setState({ customerGroups: value, customerGroupIds });
    }
  };

  return (
    <div className="panel panel-default">
      <div className="panel-heading">Filters</div>
      <div className="panel-body">
        <div className="row">
          <div className="col-lg-12">
            <div className="col-md-3">
              <div className="form-group">
                <CustomerSelect
                  multiselect
                  label="Customer Group"
                  placeholder="Search by name"
                  value={state.customerGroups}
                  onChange={onChangeCustomerGroup}
                  resetValue={[]}
                  options={data}
                  valueKey="src_id"
                  labelKey="name"
                  name="store-search-name"
                />
              </div>
            </div>
            <div className="col-md-3">
              <FilterField
                title="Customer ID"
                onChange={onChangeValue}
                keyField="customerIds"
                helpLabel="Multiple IDs separated by comma."
              />
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <FilterField
                  title="Vendor ID"
                  onChange={onChangeValue}
                  keyField="vendorIds"
                  helpLabel="Multiple IDs separated by comma."
                />
              </div>
            </div>

            <div className="col-md-2">
              <FilterField
                title="SO#"
                onChange={onChangeValue}
                keyField="soNumber"
                helpLabel="SO Number"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="col-md-3">
              <div className="form-group">
                <label className="control-label" htmlFor="startDate">
                  From
                </label>
                <AppDateTimePicker
                  id="startDate"
                  name="startDate"
                  value={state.startDate}
                  onChange={onChangeDateTime('startDate', COLUMN_TYPE_DATE)}
                  placeholder="Enter start date"
                  customInput
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="control-label" htmlFor="startDate">
                  To
                </label>
                <AppDateTimePicker
                  id="endDate"
                  name="endDate"
                  value={state.endDate}
                  onChange={onChangeDateTime('endDate', COLUMN_TYPE_DATE)}
                  placeholder="Enter end date"
                  customInput
                />
              </div>
            </div>
            <div className="col-md-1">
              <SearchButton onClick={onSearch} isSearching={isSearching} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
