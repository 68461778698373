import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { modalStyles } from '../../utils/theme';
import { uploadPOSImage, closePOSModal, selectFile } from './actions';
import { POS_FILE_SELECTED_STATE } from '../../utils/constants';
import './styles.css';

const ProofOfCompletionModal = (props) => {
  const dispatch = useDispatch();
  const so = useSelector((store) => store.proofOfServiceReducer.so);

  const formatDate = (date) => {
    if (!date) {
      return '';
    }
    return date.replace(/-/g, '/');
  };
  const scheduledStart = formatDate(so.scheduled_start);
  const scheduledEnd = formatDate(so.scheduled_end);
  const isOpen = useSelector((store) => store.proofOfServiceReducer.isOpen);
  const fileName = useSelector((store) => store.proofOfServiceReducer.fileName);
  const fileSize = useSelector((store) => store.proofOfServiceReducer.fileSize);
  const image = useSelector((store) => store.proofOfServiceReducer.image);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  let fileUploadInput = null;

  const onFileUploadChange = () => {
    const file = fileUploadInput.files[0];
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = (() => (event) => {
      const totalBytes = file.size;
      let size;
      if (totalBytes < 1000000) {
        size = `${Math.floor(totalBytes / 1000)} KB`;
      } else {
        size = `${Math.floor(totalBytes / 1000000)} MB`;
      }
      dispatch(selectFile(file.name, size, event.target.result, so.id));
    })(file);
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onUploadPhotoClick = () => {
    if (selectedFile) {
      dispatch(uploadPOSImage(so.id, selectedFile));
      dispatch(closePOSModal());
    }
  };

  const onClose = () => {
    dispatch(closePOSModal());
  };

  const FileData = () => {
    return (
      <div>
        <b>Location Name: </b>
        {so.location_name}
        <br />
        <b>Customer Id: </b>
        {so.customer_id}
        <br />
        <b>Store Number: </b>
        {so.store_number}
        <br />
        <b>Sevice Type: </b>
        {so.project_type}
        <br />
        <b>Service Dates: </b>
        {`${scheduledStart} - ${scheduledEnd}`}
        <br />
        {fileName && fileSize && `${fileName} - ${fileSize}`}
        <br />
      </div>
    );
  };

  const FileImage = () => {
    return (
      <div>
        {image !== null && (
          <img
            alt="Proof of Service"
            id="image"
            className="center"
            src={image}
            style={{ width: '100px', maxHeight: '100px' }}
          />
        )}
      </div>
    );
  };

  const FileUploadInput = () => {
    const posUploads = useSelector((store) => store.proofOfServiceReducer.posUploads);
    const posUpload = posUploads.filter(
      (item) => item.id === so.id && so.state !== POS_FILE_SELECTED_STATE
    )[0];
    const uploadState = posUpload ? posUpload.state : null;
    let submitDisabled = false;
    let submitLabel = 'Submit';
    if (uploadState !== null && uploadState !== POS_FILE_SELECTED_STATE) {
      submitLabel = uploadState;
      submitDisabled = true;
    }
    return (
      <div>
        <label htmlFor="file-upload" className="btn btn-primary centered-button">
          <input
            id="file-upload"
            type="file"
            accept="image/x-png,image/gif,image/jpeg,application/pdf"
            style={{ display: 'none' }}
            ref={(input) => {
              fileUploadInput = input;
            }}
            onChange={onFileUploadChange}
          />
          Select File
        </label>
        <button
          type="button"
          disabled={submitDisabled}
          className="btn btn-primary  btn-modal-save"
          onClick={onUploadPhotoClick}
        >
          <span>{submitLabel}</span>
        </button>
      </div>
    );
  };

  return (
    <Modal
      style={modalStyles}
      isOpen={isOpen}
      onRequestClose={props.onClose}
      contentLabel="Add Proof of Service"
    >
      <div className="row">
        <div className="form-group pull-right">
          <button type="button" className="btn btn-default btn-no-background" onClick={onClose}>
            <span className="glyphicon glyphicon-remove" />
          </button>
        </div>
        <h2 className="modal-title">Add Proof of Service</h2>
      </div>
      <div className="row">
        <div className="col-xs-8">
          <FileData />
        </div>
        <div className="col-xs-4">
          <FileImage />
        </div>
      </div>
      <div className="row">
        <div className="form-group pull-right">
          <FileUploadInput />
        </div>
      </div>
    </Modal>
  );
};

export default ProofOfCompletionModal;
