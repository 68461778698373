export const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    margin: '30px auto',
    overflow: 'visible', // fix for datepicker
  },
};

export const notificationsStyle = {
  Containers: {
    DefaultStyle: {
      width: 400,
      padding: '0 16px 16px 16px',
    },
  },
  NotificationItem: {
    DefaultStyle: {
      borderRadius: 0,
      margin: '16px 0 0',
      padding: '16px',
      borderTop: 0,
      backgroundColor: '#555',
      color: '#fff',
    },
    error: {
      backgroundColor: '#A30019',
    },
  },
  Title: {
    DefaultStyle: {
      color: '#fff',
    },
  },
  Dismiss: {
    DefaultStyle: {
      backgroundColor: 'transparent',
      borderRadius: 0,
      color: '#fff',
    },
  },
};

export default modalStyles;
