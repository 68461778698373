import React from 'react';
import ServiceOrdersFilters from '../Filters';

const TableHeader = ({ isSearching, user, onUpdateFilter }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <ServiceOrdersFilters onUpdate={onUpdateFilter} isSearching={isSearching} />
      </div>
    </div>
  );
};

export default TableHeader;
