import React from 'react';

const FilterField = ({ title, keyField, helpLabel, onChange }) => {
  return (
    <div className="form-group">
      <label className="control-label" htmlFor="customerIds">
        {title}
      </label>
      <input
        id={keyField}
        type="text"
        name={keyField}
        onChange={onChange}
        className="filter-control"
      />
      <p className="help-text">{helpLabel}</p>
    </div>
  );
};

export default FilterField;
