import * as api from '../../api/serviceOrders';
import pdfLogo from './pdf-icon.png';

export const OPEN_POS_MODAL = 'projects-client/service-orders/OPEN_POS_MODAL';
export const openPOSModal = (so) => ({ type: OPEN_POS_MODAL, so });

export const CLOSE_POS_MODAL = 'projects-client/service-orders/CLOSE_POS_MODAL';
export const closePOSModal = (so) => ({ type: CLOSE_POS_MODAL, so });

export const FILE_SELECTED = 'projects-client/service-orders/FILE_SELECTED';
export const selectFile = (fileName, fileSize, file, posId) => {
  let image = file;
  if (file.includes('application/pdf')) {
    image = pdfLogo;
  }
  return {
    type: FILE_SELECTED,
    fileName,
    fileSize,
    image,
    posId,
  };
};

export const UPLOAD_POS_IMAGE_REQUEST = 'projects-client/service-orders/UPLOAD_POS_IMAGE_REQUEST';
export const uploadPOSImageRequest = (params) => ({ type: UPLOAD_POS_IMAGE_REQUEST, params });

export const UPLOAD_POS_IMAGE_RESPONSE = 'projects-client/service-orders/UPLOAD_POS_IMAGE_RESPONSE';
export const uploadPOSImageResponse = (response, params) => ({
  type: UPLOAD_POS_IMAGE_RESPONSE,
  response,
  params,
});

export const UPLOAD_POS_IMAGE_ERROR = 'projects-client/service-orders/UPLOAD_POS_IMAGE_ERROR';
const uploadPOSImageError = (error, params) => ({ type: UPLOAD_POS_IMAGE_ERROR, error, params });

export function uploadPOSImage(id, selectedFile) {
  return async (dispatch) => {
    const params = {
      id,
      selectedFile,
    };
    try {
      dispatch(uploadPOSImageRequest(params));
      const res = await api.uploadPOSImage(params);
      dispatch(uploadPOSImageResponse(res, params));
    } catch (error) {
      const newError = {
        ...error,
        prefixMessage: 'Error occurred uploading POS image, error:',
      };
      dispatch(uploadPOSImageError(newError, params));
    }
  };
}
