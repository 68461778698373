import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PAGE_OPTIONS } from '../../../utils/constants';
import Pagination from '../../common/Pagination';

const TableFooter = ({ storeName, changePageAction }) => {
  const dispatch = useDispatch();
  const currentPage = useSelector((store) => store[storeName].currentPage);
  const totalPages = useSelector((store) => store[storeName].totalPages);
  const pagination = useSelector((store) => store[storeName].pagination);

  const onPageChange = (page) => {
    const pageSize = pagination.max_results;
    const newPagination = { offset: (page - 1) * pageSize };
    dispatch(changePageAction(newPagination));
  };

  const onChangeMaxResults = (event) => {
    const newPagination = { offset: 0, max_results: event.target.value };
    dispatch(changePageAction(newPagination));
  };

  const showPaginator = currentPage && totalPages && totalPages >= currentPage;

  if (!showPaginator) {
    return <div />;
  }

  return (
    <div className="row" style={{ marginTop: '1em' }}>
      <div className="col-sm-2">
        <div className="form-group">
          <select
            value={pagination.max_results}
            onChange={onChangeMaxResults}
            className="form-control"
            style={{ width: 'auto' }}
          >
            {PAGE_OPTIONS.map((item) => (
              <option key={item.value} id={item.value} value={item.value}>
                {item.displayName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="col-sm-8">
        <div className="text-center">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onChange={onPageChange}
            siblingPagesRange={1}
            boundaryPagesRange={1}
          />
        </div>
      </div>
    </div>
  );
};

export default TableFooter;
