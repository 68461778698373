import React from 'react';
import { useSelector } from 'react-redux';
import { POS_FILE_SELECTED_STATE } from '../../../utils/constants';

const UploadPOSButton = ({ row, onClick }) => {
  const posUploads = useSelector((store) => store.proofOfServiceReducer.posUploads);
  const posUpload = posUploads.filter(
    (item) => item.id === row.id && item.state !== POS_FILE_SELECTED_STATE
  )[0];
  let buttonLabel = 'Add Proof';
  let isDisabled = false;
  if (posUpload) {
    buttonLabel = posUpload.state;
    isDisabled = true;
  }
  return (
    <div>
      <button
        className={`btn btn-primary ${isDisabled && 'disabled'}`}
        type="button"
        onClick={onClick}
      >
        {buttonLabel}
      </button>
    </div>
  );
};

export default UploadPOSButton;
