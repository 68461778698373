import { get, post } from './api';

const SERVICE_ORDERS_ENDPOINT = '/api/service-orders';

/**
 * helper for GET /service-orders endpoint
 * @param params keys available ()
 */
export function getServiceOrders(params) {
  return get(SERVICE_ORDERS_ENDPOINT, params);
}

/**
 * helper for POST /service-orders/:id/proof-of-service endpoint
 * @param params keys available ()
 */
export function uploadPOSImage(params) {
  const data = new FormData();
  const headers = {};
  data.append('file', params.selectedFile);
  return post(`${SERVICE_ORDERS_ENDPOINT}/${params.id}/proof-of-service`, data, headers);
}

export default {
  getServiceOrders,
};
