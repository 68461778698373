import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ user, logoutUrl }) => {
  const onLogout = (e) => {
    const url = `${logoutUrl}?RelayState=${window.location.hash.replace('#', '')}`;
    e.preventDefault();
    window.location = url;
  };

  return (
    <footer>
      <span>{user}</span>
      <a href={logoutUrl} onClick={onLogout}>
        <i className="fa fa-sign-out" />
        <span>Logout</span>
      </a>
    </footer>
  );
};

Footer.propTypes = {
  logoutUrl: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
};

export default Footer;
