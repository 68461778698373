import { get } from './api';

const PARENT_CUSTOMER_GROUPS_ENDPOINT = '/api/service-orders/parent-customer-groups';
/**
 * helper for GET /location_alert_conditions/customer_groups endpoint
 * @param params
 */
export function getParentCustomerGroups(params) {
  return get(PARENT_CUSTOMER_GROUPS_ENDPOINT, params);
}

export default { getParentCustomerGroups };
