import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import appReducers from './reducer';
import { authMiddleware, notificationsMiddleware } from './middleware';

const initialState = {};

// Setup logger tools
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Define middlewares
const middlewares = [thunk, authMiddleware, notificationsMiddleware];

// Define enhancers
const enhancers = [applyMiddleware(...middlewares)];

const store = createStore(appReducers, initialState, composeEnhancers(...enhancers));

export default store;
