import React from 'react';
import DatePicker from 'react-datepicker';

import PropTypes from 'prop-types';
import moment from 'moment';
import AppDateTimePickerInput from '../AppDateTimePickerInput';
import { DATE_FORMAT_DATE_FNS, TIME_UI_FORMAT_DATE_FNS } from '../../../utils/constants';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

/**
 * react-datetime
 * https://github.com/Hacker0x01/react-datepicker
 *
 * Refer to this documentation to understand, how the date picker value is passed to the input.
 * https://github.com/Hacker0x01/react-datepicker/tree/master/docs-site/src/examples
 */
class AppDateTimePicker extends React.Component {
  removeValue = () => {
    this.props.onChange('');
    if (this.datePicker) {
      this.datePicker.setOpen(false);
    }
  };

  render() {
    return (
      <DatePicker
        ref={(datePicker) => {
          this.datePicker = datePicker;
        }}
        className="form-control"
        id={this.props.id}
        name={this.props.name}
        selected={this.props.value && this.props.value.toDate()}
        onChange={(value) => this.props.onChange(moment(value))}
        dateFormat={this.props.dateFormat}
        showTimeSelect={this.props.showTimeSelect}
        timeFormat={this.props.timeFormat}
        timeIntervals={this.props.timeIntervals}
        timeCaption={this.props.timeCaption}
        placeholderText={this.props.placeholder}
        customInput={
          this.props.customInput && (
            <AppDateTimePickerInput
              onRemove={this.removeValue}
              removeButton={this.props.customRemoveButton}
            />
          )
        }
        onBlur={this.props.onBlur}
        popperClassName={this.props.showTimeSelect && 'react-datepicker-popper-big-width'}
      />
    );
  }
}

AppDateTimePicker.defaultProps = {
  id: '',
  name: '',
  placeholder: '',
  value: moment(),
  dateFormat: DATE_FORMAT_DATE_FNS,
  showTimeSelect: false,
  timeFormat: TIME_UI_FORMAT_DATE_FNS,
  timeIntervals: undefined,
  timeCaption: 'Time',
  onBlur: () => {},
  customInput: false,
  customRemoveButton: false,
};

AppDateTimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(moment)]),
  dateFormat: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  timeFormat: PropTypes.string,
  timeIntervals: PropTypes.number,
  timeCaption: PropTypes.string,
  onBlur: PropTypes.func,
  customInput: PropTypes.bool,
  customRemoveButton: PropTypes.bool,
};

export default AppDateTimePicker;
