import React from 'react';

const SearchButton = ({ isSearching, onClick }) => {
  return (
    <div className="form-group">
      <label className="control-label" htmlFor="searchButton">
        &nbsp;
      </label>
      <button
        id="searchButton"
        type="button"
        className={
          isSearching ? 'btn btn-primary  search-button disabled' : 'btn btn-primary  search-button'
        }
        onClick={onClick}
      >
        {isSearching ? 'Searching...' : 'Search'}
      </button>
    </div>
  );
};

export default SearchButton;
