import Rollbar from 'rollbar';

export const getRollbarInstance = (config) => {
  if (config) {
    const accessToken = atob(config.accessToken);
    return new Rollbar({ ...config, accessToken });
  }
  return undefined;
};

export default getRollbarInstance;
