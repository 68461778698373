import { ceil } from 'lodash';
import {
  SERVICE_ORDERS_REQUEST,
  SERVICE_ORDERS_RESPONSE,
  SERVICE_ORDERS_ERROR,
  SET_FILTER,
  SET_PAGINATION,
} from './actions';

import { SESSION_RESPONSE } from '../App/actions';

import { DEFAULT_MAX_RESULTS } from '../../utils/constants';

const initialState = {
  customerGroups: [],
  data: { results: [] },
  filter: {},
  pagination: {
    offset: 0,
    max_results: DEFAULT_MAX_RESULTS,
  },
  currentPage: 0,
  totalPages: 0,
  isSearching: false,
  noResultsFound: false,
  serviceOrder: {},
  serviceOrders: [],
};

const serviceOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_RESPONSE:
      return {
        ...state,
        user: {
          name: action.session.user,
          roles: action.session.roles,
          rights: action.session.rights,
        },
      };
    case SERVICE_ORDERS_REQUEST:
      return { ...state, isLoading: true, isSearching: true };
    case SERVICE_ORDERS_RESPONSE: {
      const currentPage = ceil(action.response.offset / action.response.results_per_page + 1);
      const totalPages = ceil(action.response.total_count / action.response.results_per_page);
      return {
        ...state,
        error: null,
        data: action.response,
        noResultsFound: action.response.results.length === 0,
        isLoading: false,
        isSearching: false,
        currentPage,
        totalPages,
      };
    }
    case SERVICE_ORDERS_ERROR:
      return {
        ...state,
        error: { ...action.error },
        exportingToCSV: false,
        isLoading: false,
        isSearching: false,
      };
    case SET_FILTER:
      return {
        ...state,
        error: null,
        filter: action.filter,
      };
    case SET_PAGINATION:
      return {
        ...state,
        error: null,
        pagination: {
          ...state.pagination,
          ...action.pagination,
        },
      };
    default:
      return state;
  }
};

export default serviceOrdersReducer;
