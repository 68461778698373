import * as api from '../../api/session';

export const SESSION_REQUEST = 'projects-client/App/SESSION_REQUEST';

export const sessionRequest = () => ({
  type: SESSION_REQUEST,
});

export const SESSION_RESPONSE = 'projects-client/App/SESSION_RESPONSE';

export const sessionResponse = (session) => ({
  type: SESSION_RESPONSE,
  session,
});

export const SESSION_ERROR = 'projects-client/App/SESSION_ERROR';

export const sessionError = (error) => ({
  type: SESSION_ERROR,
  error,
});

export const fetchConfig = () => (dispatch, getState) => {
  dispatch(sessionRequest());
  return api
    .getSession()
    .then((response) => dispatch(sessionResponse(response)))
    .catch((error) => dispatch(sessionError(error)));
};
