import {
  UPLOAD_POS_IMAGE_ERROR,
  UPLOAD_POS_IMAGE_REQUEST,
  UPLOAD_POS_IMAGE_RESPONSE,
  OPEN_POS_MODAL,
  CLOSE_POS_MODAL,
  FILE_SELECTED,
} from './actions';

import {
  POS_UPLOADING_STATE,
  POS_UPLOADED_STATE,
  POS_FAILED_STATE,
  POS_FILE_SELECTED_STATE,
} from '../../utils/constants';

const initialState = {
  submitEnabled: false,
  isOpen: false,
  image: null,
  selectedFile: null,
  fileName: '',
  fileSize: '',
  so: {},
  posUploads: [],
};

const setPOSUploadState = (state, soId, soUploadState) => {
  const posUploads = state.posUploads;
  const soFound = posUploads.filter((so) => so.id === soId).length > 0;
  if (soFound) {
    // Update existing upload
    return posUploads.map((item) => {
      if (item.id === soId) {
        return {
          ...item,
          state: soUploadState,
        };
      }
      return item;
    });
  }
  // Create new upload
  posUploads.push({
    id: soId,
    state: soUploadState,
  });
  return posUploads;
};

const proofOfCompletionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_POS_MODAL:
      return {
        ...state,
        isOpen: false,
      };
    case OPEN_POS_MODAL:
      return {
        ...state,
        so: action.so,
        fileName: '',
        fileSize: '',
        image: null,
        selectedFile: null,
        submitEnabled: false,
        isOpen: true,
      };
    case FILE_SELECTED:
      return {
        ...state,
        fileName: action.fileName,
        fileSize: action.fileSize,
        image: action.image,
        submitEnabled: true,
        posUploads: setPOSUploadState(state, action.posId, POS_FILE_SELECTED_STATE),
      };
    case UPLOAD_POS_IMAGE_REQUEST:
      return {
        ...state,
        isUploading: true,
        posUploads: setPOSUploadState(state, action.params.id, POS_UPLOADING_STATE),
      };
    case UPLOAD_POS_IMAGE_RESPONSE:
      return {
        ...state,
        isUploading: false,
        submitEnabled: true,
        posUploads: setPOSUploadState(state, action.params.id, POS_UPLOADED_STATE),
      };
    case UPLOAD_POS_IMAGE_ERROR:
      return {
        ...state,
        isUploading: false,
        submitEnabled: true,
        uploading: true,
        posUploads: setPOSUploadState(state, action.params.id, POS_FAILED_STATE),
      };
    default:
      return state;
  }
};

export default proofOfCompletionReducer;
