import PropTypes from 'prop-types';

export const DATE_FORMAT = 'MM-DD-YYYY';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';

export const TABLE_SEARCH_TEXT = 'Please enter search parameters to begin.';
export const TABLE_NO_RESULTS_TEXT = 'No matching results found.';

export const TIME_UI_FORMAT = 'hh:mm A';
export const DATE_FORMAT_DATE_FNS = 'MM-dd-yyyy';
export const TIME_UI_FORMAT_DATE_FNS = 'hh:mm a';

export const DEFAULT_MAX_RESULTS = 10;

export const COLUMN_TYPE_DATE = 'date';

export const NO_INDEX = -1;

export const USER_PROP_SHAPE = PropTypes.shape({
  user: PropTypes.string,
  roles: PropTypes.array,
  rights: PropTypes.array,
});

export const PAGE_OPTIONS = [
  { value: 10, displayName: '10' },
  { value: 25, displayName: '25' },
  { value: 30, displayName: '30' },
  { value: 50, displayName: '50' },
];

export const EMPTY_VALUE = '';

export const COLUMN_TYPE_TEXT = 'text';
export const COLUMN_TYPE_NUMBER = 'number';

export const POS_UPLOADING_STATE = 'Uploading...';
export const POS_FILE_SELECTED_STATE = 'Selected';
export const POS_UPLOADED_STATE = 'Uploaded';
export const POS_FAILED_STATE = 'Failed';
