import * as api from '../../api/customerGroups';

export const CUSTOMER_GROUPS_REQUEST = 'projects-client/CUSTOMER_GROUPS_REQUEST';

const loadCustomerRequest = () => ({ type: CUSTOMER_GROUPS_REQUEST });

export const CUSTOMER_GROUPS_RESPONSE = 'projects-client/CUSTOMER_GROUPS_RESPONSE';

const loadCustomerResponse = (response) => ({ type: CUSTOMER_GROUPS_RESPONSE, response });

export const CUSTOMER_GROUPS_ERROR = 'projects-client/CUSTOMER_GROUPS_ERROR';

const loadCustomerError = (error) => ({ type: CUSTOMER_GROUPS_ERROR, error });

export function loadCustomerGroups() {
  return async (dispatch) => {
    try {
      dispatch(loadCustomerRequest());
      const res = await api.getParentCustomerGroups();
      res.skipNotification = true;
      dispatch(loadCustomerResponse(res));
    } catch (error) {
      const newError = { ...error };
      newError.prefixMessage = 'Error occurred loading customer groups, error:';
      dispatch(loadCustomerError(newError));
    }
  };
}
