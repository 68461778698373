import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Notifications from 'react-notification-system-redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import * as action from './actions';
import { useBeforeFirstRender } from '../../utils/hooks';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import ServiceOrders from '../ServiceOrders';
import { notificationsStyle } from '../../utils/theme';
import { getRollbarInstance } from '../../utils/rollbar';
import './styles.css';

const NoMatch = ({ location }) => (
  <div>
    <h3>
      No match for
      <code>{location.pathname}</code>
    </h3>
  </div>
);

const App = () => {
  const notifications = useSelector((store) => store.notifications);
  const rollbar = useSelector((store) => store.appReducer.rollbar);
  const redirect = useSelector((store) => store.appReducer.redirect);
  const configuration = useSelector((store) => store.appReducer.configuration);
  const logoutUrl = useSelector((store) => store.appReducer.logoutUrl);
  const requestingConfig = useSelector((store) => store.appReducer.requestingConfig);
  const user = useSelector((store) => store.appReducer.user);

  const dispatch = useDispatch();

  useBeforeFirstRender(() => {
    dispatch(action.fetchConfig());
  });

  if (rollbar) {
    window.Rollbar = getRollbarInstance(rollbar);
  }
  if (redirect || requestingConfig || !user || !configuration) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Notifications
        notifications={notifications}
        style={notificationsStyle}
        allowHTML
        noAnimation
      />
      <Header title="Projects" home={configuration.homeUrl} help={configuration.helpUrl} />
      <div className="container-fluid">
        <div className="col-xs-12">
          <Switch>
            <Route exact path="/" component={ServiceOrders} />
            <Route component={NoMatch} />
          </Switch>
        </div>
      </div>
      <Footer user={user} logoutUrl={logoutUrl} />
    </div>
  );
};

export default withRouter(App);
