import { combineReducers } from 'redux';
import { reducer as notifications } from 'react-notification-system-redux';
import serviceOrdersReducer from './containers/ServiceOrders/reducer';
import customerGroupsReducer from './containers/CustomerSelect/reducer';
import proofOfServiceReducer from './containers/ProofOfCompletionModal/reducer';
import appReducer from './containers/App/reducer';

const appReducers = combineReducers({
  appReducer,
  serviceOrdersReducer,
  customerGroupsReducer,
  proofOfServiceReducer,
  notifications,
});

export default appReducers;
