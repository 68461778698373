import { SESSION_REQUEST, SESSION_RESPONSE, SESSION_ERROR } from './actions';

const initialState = {};

function appReducer(state = initialState, action) {
  switch (action.type) {
    case SESSION_REQUEST:
      return {
        ...state,
        loadingSession: true,
      };
    case SESSION_RESPONSE:
      return {
        ...state,
        ...action.session,
        loadingSession: false,
      };
    case SESSION_ERROR:
      return {
        ...state,
        error: { ...action.error },
        loadingSession: false,
        failedSession: true,
      };
    default:
      return state;
  }
}

export default appReducer;
