import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './styles.css';

class AppDateTimePickerInput extends React.Component {
  handleOnKeyPress = (onClick) => (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onClick();
    }
  };

  render() {
    return (
      <div className="input-group">
        <input
          id={`${this.props.id}-input`}
          value={this.props.value}
          type="text"
          className="form-control input-filter"
          onClick={this.props.onClick}
          onChange={() => {}}
          onBlur={this.props.onBlur}
          onKeyPress={this.handleOnKeyPress(this.props.onClick)}
        />
        <span className="input-group-addon">
          <span
            className="glyphicon glyphicon-calendar"
            onClick={this.props.onClick}
            role="button"
            tabIndex="0"
            onKeyPress={this.handleOnKeyPress(this.props.onClick)}
          />
        </span>
        {this.props.removeButton && (
          <span className="input-group-addon">
            <span
              className="glyphicon glyphicon-remove"
              onClick={this.props.onRemove}
              role="button"
              tabIndex="0"
              onKeyPress={this.handleOnKeyPress(this.props.onRemove)}
            />
          </span>
        )}
      </div>
    );
  }
}

AppDateTimePickerInput.defaultProps = {
  id: '',
  value: moment(),
  onClick: () => {},
  onBlur: () => {},
  removeButton: false,
  onRemove: () => {},
};

AppDateTimePickerInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(moment)]),
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  removeButton: PropTypes.bool,
  onRemove: PropTypes.func,
};

export default AppDateTimePickerInput;
