import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ help, home, title }) => {
  const onBackClick = (event) => {
    event.preventDefault();
    window.history.back();
  };

  return (
    <div>
      <nav className="main-navigation">
        <a href="/back" onClick={onBackClick}>
          <i className="fa fa-arrow-circle-left" />
        </a>
        <a href={home}>
          <i className="fa fa-home" />
        </a>
        <a className="fresh-help" href={help}>
          <i className="fa fa-question" />
        </a>
        <a className="app-title-header">
          <i className="fa fa-briefcase" />
          <span> {title} </span>
        </a>
      </nav>
    </div>
  );
};

Header.defaultProps = {
  home: '/',
  help: '/',
};

Header.propTypes = {
  home: PropTypes.string,
  help: PropTypes.string,
};

export default Header;
