import { get } from './api';

/**
 * helper for GET /session endpoint
 * @param params
 */
export function getSession() {
  const path = window.location.hash.replace('#', '');
  const SESSION_ENDPOINT = `/api/session?RelayState=${path}`;
  return get(SESSION_ENDPOINT);
}

export default {
  getSession,
};
