import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import TableHeader from '../../components/ServiceOrders/TableHeader';
import TableFooter from '../../components/ServiceOrders/TableFooter';
import UploadPOSButton from '../../components/common/UploadPOSButton';
import { changeFilter, changePagination } from './actions';
import { openPOSModal } from '../ProofOfCompletionModal/actions';
import ProofOfCompletionModal from '../ProofOfCompletionModal';
import './styles.css';

const ServiceOrders = () => {
  const dispatch = useDispatch();

  const isSearching = useSelector((store) => store.serviceOrdersReducer.isSearching);
  const data = useSelector((store) => store.serviceOrdersReducer.data.results);
  const user = useSelector((store) => store.user);
  const [selectedRow, setSelectedRow] = useState({});

  const onOpenPOCModal = (row) => {
    setSelectedRow(row);
    dispatch(openPOSModal(row));
  };

  const COLUMNS = [
    {
      text: 'STORE',
      dataField: 'location_name',
    },
    {
      text: 'STATE',
      dataField: 'state',
    },
    {
      text: 'SERVICE',
      dataField: 'project_type',
    },
    {
      text: 'SUBTYPE',
      dataField: 'subservice_name',
    },
    {
      text: 'SO#',
      dataField: 'so_number',
    },
    {
      text: 'SCHEDULED START',
      dataField: 'scheduled_start',
    },
    {
      text: 'SCHEDULED END',
      dataField: 'scheduled_end',
    },
    {
      text: 'START',
      dataField: 'start',
    },
    {
      text: 'END',
      dataField: 'end',
    },
    {
      text: 'START TIME',
      dataField: 'start_time',
    },
    {
      text: 'END TIME',
      dataField: 'end_time',
    },
    {
      text: 'ACTIONS',
      dataField: 'uploadPOC',
      isDummyField: true,
      headerStyle: {
        width: '150px',
      },
      formatter: (cellContent, row) => {
        const onClick = () => {
          onOpenPOCModal(row);
        };
        return <UploadPOSButton row={row} onClick={onClick} />;
      },
    },
  ];

  const onUpdateFilter = (filters) => {
    const params = {
      q: filters.soNumber,
      from_scheduled_date: filters.startDate,
      to_scheduled_date: filters.endDate,
      so_number: filters.soNumber,
      customer_ids: filters.customerIds,
      vendor_idents: filters.vendorIds,
      parent_group_ids: filters.customerGroupIds,
    };
    dispatch(changeFilter(params));
  };

  return (
    <div>
      <div className="col-xs-12">
        <TableHeader isSearching={isSearching} onUpdateFilter={onUpdateFilter} user={user} />
        <div className="col-xs-12 panel">
          <div className="table-responsive-wrapper">
            <div className="panel-body">
              <BootstrapTable keyField="id" data={data} columns={COLUMNS} />
            </div>
          </div>
        </div>
        <TableFooter storeName="serviceOrdersReducer" changePageAction={changePagination} />
      </div>
      <ProofOfCompletionModal serviceOrder={selectedRow} />
    </div>
  );
};

export default withRouter(ServiceOrders);
