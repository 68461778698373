import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { useBeforeFirstRender } from '../../utils/hooks';
import { loadCustomerGroups } from './actions';
import './style.css';

const formatOptions = (data, valueKey, labelKey) =>
  data.map((store) => ({ value: store[valueKey], label: store[labelKey] }));

const CustomerSelect = (props) => {
  const options = useSelector((store) => store.customerGroupsReducer.customerGroups);
  const dispatch = useDispatch();
  useBeforeFirstRender(() => {
    dispatch(loadCustomerGroups());
  });

  return (
    <div>
      {props.label && <label className="control-label">{props.label}</label>}
      <Select
        isMulti
        name={props.name && props.name}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        options={formatOptions(options, props.valueKey, props.labelKey)}
        resetValue={props.resetValue}
      />
    </div>
  );
};

export default CustomerSelect;
