import * as api from '../../api/serviceOrders';

export const SERVICE_ORDERS_REQUEST = 'projects-client/service-orders/SERVICE_ORDERS_REQUEST';
const loadRequest = (params) => ({ type: SERVICE_ORDERS_REQUEST, params });

export const SERVICE_ORDERS_RESPONSE = 'projects-client/service-orders/SERVICE_ORDERS_RESPONSE';
const loadResponse = (response, params) => ({
  type: SERVICE_ORDERS_RESPONSE,
  response,
  params,
});

export const SERVICE_ORDERS_ERROR = 'projects-client/service-orders/SERVICE_ORDERS_ERROR';
const loadError = (error) => ({ type: SERVICE_ORDERS_ERROR, error });

export const SET_PAGINATION = 'projects-client/service-orders/SET_PAGINATION';
const setPagination = (pagination) => ({ type: SET_PAGINATION, pagination });

export function getServiceOrders(params) {
  return async (dispatch, getState) => {
    try {
      dispatch(loadRequest(params));
      const cleanParams = {
        ...((params && params.filter) || getState().serviceOrdersReducer.filter),
        ...((params && params.pagination) || getState().serviceOrdersReducer.pagination),
        vendor_service_order_statuses: 'provide_proof_of_service',
      };
      const res = await api.getServiceOrders(cleanParams);
      res.skipNotification = true;
      dispatch(loadResponse(res, cleanParams));
      const pagination = {
        offset: res.offset,
        total_count: res.total_count,
        results_per_page: res.results_per_page,
        max_results: res.results_per_page,
      };
      dispatch(setPagination(pagination));
    } catch (error) {
      const newError = {
        ...error,
        prefixMessage: 'Error occurred loading Service Orders, error:',
      };
      dispatch(loadError(newError));
    }
  };
}

export function changePagination(pagination) {
  return (dispatch, getState) => {
    dispatch(setPagination(pagination));
    const params = {
      filter: getState().serviceOrdersReducer.filter,
      pagination: { ...getState().serviceOrdersReducer.pagination, ...pagination },
    };
    dispatch(getServiceOrders(params));
  };
}

// FILTER
export const SET_FILTER = 'projects-client/service-orders/SET_FILTER';
const setFilter = (filter) => ({ type: SET_FILTER, filter });

export function changeFilter(filter) {
  return (dispatch, getState) => {
    dispatch(setFilter(filter));
    const params = {
      filter,
      pagination: { ...getState().serviceOrdersReducer.pagination, offset: 0 },
    };
    dispatch(getServiceOrders(params));
  };
}
