import Notifications from 'react-notification-system-redux';
import * as HttpStatusCodes from 'http-status-codes';
import { get } from 'lodash';

const RESPONSE_SUFFIX = 'RESPONSE';
const DEFAULT_RESPONSE_MESSAGE = '';
const DEFAULT_RESPONSE_TITLE = 'Success!';
const ERROR_SUFFIX = 'ERROR';
const DEFAULT_ERROR_MESSAGE = 'Unknown error';
const DEFAULT_AUTODISMISS = 5;

const authMiddleware = () => (next) => (action) => {
  const isError = action.type && action.type.match(new RegExp(`${ERROR_SUFFIX}$`, 'g'));
  if (isError && action.error && action.error.status === HttpStatusCodes.UNAUTHORIZED) {
    if (action.error.body && action.error.body.redirect) {
      window.location = action.error.body.redirect;
    }
  }
  if (action.response && action.response.redirect) {
    window.location = action.response.redirect;
  }
  next(action);
};

const notificationsMessageBuilder = (prefixMessage, message, defaultMessage) => {
  if (prefixMessage && message) {
    return `${prefixMessage}<br/>${message}`;
  }
  if (message) {
    return message;
  }
  return defaultMessage || '';
};

const buildNotification = (actionResponse, dispatch) => {
  let notification = {
    title: actionResponse.title || DEFAULT_RESPONSE_TITLE,
    autoDismiss: actionResponse.autoDismiss || DEFAULT_AUTODISMISS,
  };
  if (actionResponse.noDismiss) {
    dispatch(Notifications.removeAll());
    notification = { ...notification, autoDismiss: 0 };
  }
  return notification;
};

const notificationsMiddleware = ({ dispatch }) => (next) => (action) => {
  const isRespone = action.type && action.type.match(new RegExp(`${RESPONSE_SUFFIX}$`, 'g'));
  if (isRespone && action.response && !action.response.skipNotification) {
    const notification = buildNotification(action.response, dispatch);
    notification.message = notificationsMessageBuilder(
      action.response.prefixMessage,
      action.response.message,
      DEFAULT_RESPONSE_MESSAGE
    );
    dispatch(Notifications.success(notification));
  }
  const isError = action.type && action.type.match(new RegExp(`${ERROR_SUFFIX}$`, 'g'));
  if (isError && action.error && !action.error.skipNotification) {
    const notification = buildNotification(action.error, dispatch);
    const message =
      get(action.error, 'message') ||
      get(action.error, 'body.message') ||
      get(action.error, 'body.error.error.code') ||
      get(action.error, 'statusText') ||
      DEFAULT_ERROR_MESSAGE;
    notification.message = notificationsMessageBuilder(action.error.prefixMessage, message);

    dispatch(Notifications.error(notification));
  }
  next(action);
};

export { authMiddleware, notificationsMiddleware };
